import axios from "axios";
import qs from "qs";
import {ElMessage} from 'element-plus'
import store from "../store/index";
import router from "../router/index";
const Base64 = require('js-base64').Base64
import md5 from 'md5'

const encryptReq = (data) => { 
  let timestamp = new Date().getTime()
  let reqData = Base64.encode(JSON.stringify(data))
  let sign = 'data=' + reqData + '&timestamp=' + timestamp + 'd#~*HF#)+s321-&A%^2_M@$'
  for (let i = 0; i < 3; i++) {
    sign = md5(sign)
  }
  return {
    data: reqData,
    timestamp,
    sign
  }
}
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST
})
//设置超时
instance.defaults.timeout = 60000;

instance.interceptors.request.use(
  (config:any) => {
    const token = store.state.token
    if(config.data instanceof FormData){ // 上传文件
      console.log(config.url,'------', config.data.get('file'))
      let data = JSON.parse(config.data.get('data'))
      data = encryptReq(data)
      let form = new FormData()
      for(let k in data){
        form.append(k,data[k])
      }
      form.append('file',config.data.get('file'))
      config.data = form
      config.headers["Content-Type"] = "multipart/form-data"
    } else { // 非上传
      console.log(config.url,'------',qs.parse(config.data))
      config.data = qs.stringify(encryptReq(config.data))
      config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8" 
    }
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = token
    }
    return config;
  },
  (error:any) => {
    return Promise.reject(error);
  }
);
// 返回至数据类型
interface resType{
  msg:string|null
  result:any
  code:number | string,
  isok:number
}
instance.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response.data);
    }
  },
  error => {
    ElMessage.error('网络错误')
    return Promise.reject(error);
    // 请求异常
  }
);
const post = function(url:string = '', data:object|FormData,config?:object) {
  return new Promise((resolve?:(value:any) => void, reject?:(value:any) => void) => {
    instance({
        method: 'post',
        url,
        data: data,
        ...config
      })
      .then(res => {
        const resData = res as resType
        const result = resData.result
        const msg = resData.msg
        const isok = resData.isok
        const code = resData.code
        if(isok === 1){
          resolve(result)
        } else {
          if(msg){
            ElMessage.error(msg)
            reject(result)
          } else {
            ElMessage.error('请求失败')
            reject(result)
          }
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}
const get = function(url:string = '', data:object = {},config?:object) {
  return new Promise((resolve?:(value:any) => void, reject?:(value:any) => void) => {
    instance({
        method: 'get',
        url,
        params: data,
        ...config
      })
      .then(res => {
        const resData = res as resType
        const result = resData.result
        const msg = resData.msg
        const isok = resData.isok
        const code = resData.code
        if(isok === 1){
          resolve(result)
        } else {
          if(msg){
            ElMessage.error(msg)
            reject(result)
          } else {
            ElMessage.error('请求失败')
            reject(result)
          }
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
export default {post,get};